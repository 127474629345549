/* loading icon */
.loader svg>circle{
  animation: rotate-360 ease-in-out 1.1s;
  animation-iteration-count:infinite;
}
/* experience page show hide button facing down */
.accordion-button:not(.collapsed):after{
  animation: floating-icon-upside-down 1.5s ease-in-out !important;
}
/* clouds on home page */
.slower_cloud:nth-of-type(odd){
    animation-delay: 2.3s !important;
}
.slower_cloud:nth-of-type(even){
    animation-delay: 4s !important;
}
.faster_cloud{
    animation-delay: 8s !important;
}
.cloud-float-left-to-right{
    opacity:0;
    animation: cloud-float-left-to-right linear 30s;
    animation-iteration-count: infinite;
}
.cloud-float-left-to-right-slow{
    opacity:0;
    animation: cloud-float-left-to-right linear 50s;
    animation-iteration-count: infinite;
}
.cloud-float-left-to-right-slower{
    opacity:0;
    animation: cloud-float-left-to-right linear 59s;
    animation-iteration-count: infinite;
}
/* footer */
#footer_wave_one{
  animation: trip-forward 10s ease-in-out infinite;
}
#footer_wave_two{
  animation: trip-forward 8s ease-in-out infinite;
  animation-delay: 0.5ms;
}
#footer_wave_three{
  animation: trip-forward 9s ease-in-out infinite;
  animation-delay: 1s;
}
.opacityPulse {
  animation: opacityPulse 3s ease-out infinite;
}
#homeStart:hover{
  animation: homePageLinkHover 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
@keyframes homePageLinkHover{
  from {
    transform: translateX(0rem);
  }
  to {
    transform: translateX(1rem);
  }
}
@keyframes opacityPulse{
  0%{
      opacity: 0.3;
  }
  30%{
      opacity: 1;
  }
  70%{
    opacity: 1;
  }
  100%{
      opacity: 0.3;
  }
}
@keyframes menu-rolldown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes menu-rollup {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
}
@keyframes float-in-from-top {
  from {
    transform: translateY(-20vh);
  }
  to {
    transform: translateY(0vh);
  }
}
@keyframes cloud-float-left-to-right{
    0%{
        opacity: 0;
        transform: translateX(0);
    }
    10%{
        opacity: 1;
        transform: translateX(10vw);
    }
    100%{
        opacity: 1;
        transform: translateX(115vw);
    }
}
@keyframes floating-icon {
	0% {
    transform:translateY(0px) scaleX(1.0);
	}
  20%{
    transform:translateY(-3px) scaleX(1);
  }
  50%{
    transform:translateY(-5px) scaleX(1.05);
  }
  80%{
    transform:translateY(-3px) scaleX(1);
  }
  100%{
    transform:translateY(0px) scaleX(1.0);
  }
	
}
@keyframes floating-speech-bubble {
	0% {
    transform:translateY(0px);
	}
  20%{
    transform:translateY(-3px);
  }
  50%{
    transform:translateY(-5px);
  }
  80%{
    transform:translateY(-3px);
  }
  100%{
    transform:translateY(0px);
  }
}
@keyframes chat-bubble-show {
	0% {
    transform:translateY(2em);
    opacity: 0;
    transform-origin: bottom;    
	}
  100%{
    transform:translateY(0em);
    opacity: 1;
    transform-origin: bottom;
  }
}
@keyframes floating-icon-upside-down{
  0% {
    transform:translateY(0px) scaleX(1.0) rotate(0deg);
	}
  20%{
    transform:translateY(-3px) scaleX(1) rotate(180deg);
  }
  50%{
    transform:translateY(-5px) scaleX(1.05) rotate(180deg);
  }
  80%{
    transform:translateY(-3px) scaleX(1) rotate(180deg);
  }
  100%{
    transform:translateY(0px) scaleX(1.0) rotate(180deg);
  }
}
@keyframes trip-forward {
  0% {
    transform: scale(1)
  }
  40% {
    transform: scale(1.2)
  }
  100% {
    transform: scale(1)
  }
}
@keyframes trip-forward-upside-down {
  0% {
    transform: rotate(180deg) translateY(10px) scale(1)
  }
  40% {
    transform: rotate(180deg) translateY(10px) scale(1.2)
  }
  100% {
    transform: rotate(180deg) translateY(10px) scale(1)
  }
}