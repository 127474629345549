#site-locked{
    align-items: center;
    background-image: radial-gradient(circle at 1px 1px, #000 1px, #0000 0);
    background-size: 40px 40px;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: center;
}
#site-locked h2{
    color: var(--neutral);
    display: flex;
    flex-direction: column;
    font-family: NeutralFace;
    font-size: var(--page-h2-font-size);
    max-width: 20em;
    padding: 1em;
    background-color: var(--accent-darker);
    box-shadow: 25px 22px 0 var(--accent-darkest), -45px -20px 0 var(--accent-darker-more), 47px -50px 0 var(--palette-dark);
}
@media only screen and (max-width: 768px) {
    #site-locked h2{
        font-size: var(--page-h3-font-size-mobile);
        box-shadow: 16px 22px 0 var(--accent-darkest),-23px -20px 0 var(--accent-darker-more),23px -50px 0 var(--palette-dark);
    }
}