#top_menu_fixed{
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  height: var(--top-menu-height);
  background-color: var(--accent);
  background: #cd491b;
  padding: 0 10%;
  z-index: 999999;
}
#top_menu_filler{
  height: 4em;
  width: 100%;
  background-color: #cd491b
}
#top_menu_name{
  flex: 2;
  text-transform: uppercase;
  color: var(--neutral);
  align-content: center;
  font-family: 'NeutralFace-Bold';
  font-size: var(----top-menu-name-font-size);
  font-style: normal;
  font-weight: 800;
  cursor: pointer;
}
#top_menu_spacer{
  flex: 3;
  max-width: 2em;
}
#top_menu_spacer svg {
  display: none;
}
#top_menu_nav {
  flex: 4;
  display: flex;
  justify-content:end;
  column-gap: 2em;
}
#top_menu_links {
  flex: 1;
  display: flex;
}
#top_menu_nav a {
  text-decoration: none;
  color:var(--neutral);
  font-family: 'NeutralFace';
  align-content: center;
  font-size:var(--top-menu-nav-font-size);
}
#top_menu_nav a.active{
  font-family: 'NeutralFace-Bold';
}
#top_menu_links svg {
  height: 2em;
  width: 2em;
  max-height: 1.3em;
}
#top_menu_links a, #top_menu_links button {
  cursor: pointer;
  flex: 1 1;
  z-index: 9999;
  background: none;
  border: none;
  padding: 0;
  align-content: center;
}
.mobile-menu-closed{
  transform: translateY(-100%);
  animation: menu-rollup 700ms ease-in-out;
}
.mobile-menu-open{
  animation: menu-rolldown 700ms ease-in-out;
}
.mobile-menu-invisible{
  display: none !important;
}
@media only screen and (max-width: 768px) {
  #top_menu_spacer svg {
    display: unset;
  }
  #top_menu_fixed{
    padding:.5em 1em;
    height:var(--top-menu-height-mobile);
    display: grid;
  }
  #top_menu_filler{
    height:auto;
    padding:3em 1em 1em 1em;
    display: flex;
    flex-direction: column;
    position:fixed;
    z-index: 999998;
    row-gap:1em;
    backdrop-filter: saturate(180%) blur(48px);
    opacity: 0.9;
  }
  #top_menu_name{
    flex: 9;
    text-align: start;
    grid-row:1;
    grid-column: 1;
  }
  #top_menu_spacer{
    flex:1;
    grid-row:1;
    grid-column: 2;
    align-self: center;
    justify-self: end;
    padding: 0.2em;
    cursor: pointer;
  }
  #top_menu_nav, #top_menu_links{
    display: none;
  }
  #top_menu_nav_mobile {
    padding-top: 0.5em;
    flex: 2;
    display: flex;
    justify-content:space-around;
    row-gap: 0.5em;
    flex-direction: column;
    text-align: end;
  }
  #top_menu_nav_mobile a {
    text-decoration: none;
    color:var(--neutral);
    font-family: 'NeutralFace';
    align-content: center;
    font-size:var(--top-menu-nav-font-size-mobile);
  }
  #top_menu_nav_mobile a.active{
    font-family: 'NeutralFace-Bold';
  }
  #top_menu_hamburger{
    grid-row: 2;
    grid-column: 1 / span 2;
  }
  #top_menu_links_mobile {
    column-gap: 1em;
    display: flex;
    flex: 1 1;
    align-self: end;
  }
  #top_menu_links_mobile svg {
    height: inherit;
    width: inherit;
    /* height: 2em;
    width: 2em;
    max-height: 1.5em; */
  }
  #top_menu_links_mobile a, #top_menu_links_mobile button {
    cursor: pointer;
    flex: 1 1;
    z-index: 9999;
    background: none;
    border: none;
    padding: 0;
    align-content: center;
    height: 1.2em;
    width: auto;
  }
}
