@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300..700&display=swap');
@font-face {
  font-family: 'NeutralFace';
  src: local('NeutralFace'), url("../fonts/NeutralFace.otf") format('opentype');
}
@font-face {
  font-family: 'NeutralFace-Bold';
  src: local('NeutralFace-Bold'), url("../fonts/NeutralFace-Bold.otf") format('opentype');
}
@font-face {
  font-family: 'Projekt-Blackbird';
  src: local('Projekt-Blackbird'), url("../fonts/Projekt Blackbird v2.otf") format('opentype');
}
@font-face {
  font-family: 'Sk-Modernist-Regular';
  src: local('Sk-Modernist-Regular'), url("../fonts/Sk-Modernist-Regular.otf") format('opentype');
}
/* 1. Use a more-intuitive box-sizing model */
*, *::before, *::after {
    box-sizing: border-box;
}
/* 2. Remove default margin */
* {
    margin: 0;
}
html {
    overflow: hidden;
    height: 100%;
  }
body {
    margin: 0 !important;
    overflow: auto;
    height: 100%;

    /* 3. Add accessible line-height */
    line-height: 1.5;
    /* 4. Improve text rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale !important;
    font-optical-sizing: auto !important;
}
/* 5. Improve media defaults */
img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}
/* 6. Inherit fonts for form controls */
input, button, textarea, select {
    font: inherit;
}
/* 7. Avoid text overflows */
p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}
/* 8. Improve line wrapping */
p {
    text-wrap: pretty;
}
h1, h2, h3, h4, h5, h6 {
    text-wrap: balance;
}
/*
9. Create a root stacking context
*/
#root, #__next {
    isolation: isolate;
}
#root{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    height: 100%;
}
.app {
    background-color: var(--palette-light);
    height:auto;
    flex: 1 1 auto;
    text-align: center;
    overflow-x:hidden;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }
.main_content{
    margin-top:var(--top-menu-height);
}
.divTitle h3{
    font-family: 'Sk-Modernist-Regular';
    font-size: var(--page-h3-font-size);
}
.divTitle h2 {
    font-family: 'Jura';
    font-size: var(--page-h2-font-size);
    color: var(--neutral);
    font-weight: 800;
}
.rounded-corners{
    border-radius: var(--border-rounded-radius);
}
.btn:hover, .btn:active{
    background-color: var(--palette-accent-warm-2) !important;
    border-color:none !important;
    color: var(--neutral) !important;
}
.invalid-feedback{
    font-size:var(--invalid-feedback-font-size);
    background-color: var(--neutral);
    text-align: center !important;
    width: 80%;
    margin: 1em auto;
    opacity: 0.8;
}
.modal-header, .modal-footer{
    justify-content: center;
    border:none;
}
.modal-body{
    text-align: center;
}
@media only screen and (max-width: 768px) {
    .divTitle h2 {
        font-size: var(--page-h2-font-size);
    }
    .divTitle h3 {
        font-size: var(--page-h3-font-size-mobile);
    }
    .main_content{
        margin-top:var(--top-menu-height-mobile);
    }
}