:root {
    --accent: #cd491b;
    --accent-darker: #b33e16;
    --accent-darker-more:#7f280a;
    --accent-darkest: #401101;
    --neutral: #ece6e1;
    --highlight:#fffbf8;
    --neutral-transparent: #ece6e178;
    --dark: #272727;
    --palette-dark: #2f2925;
    --palette-dark-transparent: #cd491b45;
    --palette-dark-transparent-2: #cd491ba3;
    --palette-light: #FFD6A5;
    --palette-lighter:#fee5c7;
    --palette-accent-warm: #cd491b;
    --palette-accent-warm-2: #ff8a2357;

    --top-menu-height: 4em;
    --top-menu-height-mobile: 3em;
    --top-menu-name-font-size: 1em;
    --top-menu-nav-font-size: 0.8em;
    --top-menu-nav-font-size-mobile: 1.1em;
    --border-rounded-radius: 15px;
    
    --page-h1-font-size: 3em;
    --page-h2-font-size: 2em;
    --page-h2-font-size-mobile: 2.1em;
    --page-h3-font-size: 1em;
    --page-h3-font-size-mobile: 1.2em;
    --page-h4-font-size: 1em;
    --page-subheading-font-size: 1rem;
    --body-font-size: 1em;
    --body-font-smaller-size:0.8em;
    --body-font-tiny-size:0.5em;
    --body-padding: 0 15%;

    --text-font-size: 1em;
    --text-font-size-mobile: 1.1em;
    --text-button-font-size: 1em;
    --text-button-font-size-mobile: 1.1em;
    --invalid-feedback-font-size: 0.8em;
  }