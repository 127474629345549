#contactFormBox{
  max-width: 80em;
  padding: 0 10%;
  margin: auto;
  flex-direction: row;
  column-gap: 4em;
}
.formContactContainer{ 
    background-color: var(--accent-darker);
    box-shadow: 25px 22px 0 var(--accent-darkest),-45px -20px 0 var(--accent-darker-more),47px -50px 0 var(--palette-dark);
    max-width: 50em;
    padding-top: 3em;
}
#contactFormContainer{
  background-color: var(--palette-light);
  background-image: radial-gradient(circle at 1px 1px, black 1px, transparent 0);
  background-size: 40px 40px;
}
#contactFormContainer .divTitle{
  flex:1;
  text-align: start;
  margin-bottom: 5em;
}
#divContact{
  flex:2;
}
#contactFormContainer .divTitle h2{
  color: var(--accent-darker-more);
  margin-bottom: 1em;
}
#contactFormContainer .divTitle h3{
  color: var(--accent-darker-more);
}
#contact_divider_1{
  transform: translateY(-5px);
}
#contact_divider_1, #contact_divider_2{
  fill: var(--palette-dark);
}
#contact_divider_1 svg{
  transform: rotate(180deg) rotateY(180deg);
}
#contact_divider_2 svg{
  transform: rotateY(180deg);
}
#formContact .form-control{
  background-color: var(--palette-light);
  border:none !important;
  border-radius: 0 !important;
}
.formContactContainer label, #formContact input, #formContact textarea{
  font-size:var(--text-font-size);
}
.formContactContainer label{
    padding: 0.8rem 2rem;
    color: var(--accent-darker-more);
    font-family: 'NeutralFace';
} 
.form-control{
  width: 80% !important;
  margin:auto;
  font-family: 'Sk-Modernist-Regular';
}
.formContactContainer button, #contactResponse button{
  font-weight: 400;
  font-family: 'NeutralFace-Bold';
  font-size: var(--text-button-font-size);
  border:none;
  width:100%;
  color: var(--palette-dark);
  padding: 0.5rem 1rem;
  border-radius: 0;
  background-color: #fa4;
  letter-spacing: .2rem;
}
#contactResponse h2{
  font-family: 'NeutralFace-Bold';
  font-weight: 400;
  font-size:1.5rem;
  font-style: normal;
}
 .reCaptcha{
    display: flex;
    justify-content: center;
    padding:1em;
 }
 #captchaFeedback{
   color:red;
   opacity: 0;
   background-color: var(--neutral);
   margin: 1em auto;
   text-align: center !important;
   padding: 0.5em;
   font-size: var(--invalid-feedback-font-size);
 }
 /* we need the validation to stay as display:block so the container can adjust for extra space */
 #formContact .invalid-feedback{
   display: block !important;
   visibility: hidden;
 }
 #formContact.is-invalid~.invalid-feedback, #formContact.is-invalid~.invalid-tooltip, #formContact.was-validated :invalid~.invalid-feedback, #formContact.was-validated :invalid~.invalid-tooltip {
   visibility: visible !important;
 }
 #formContact .mb-3{
   margin-bottom: 0 !important;
 }
 #captchaFeedback.invalid{
   opacity: 0.8;
 }
 #contactResponse{
   background-color: var(--neutral-transparent) !important;
   box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
   max-width: 35rem;
 }
 #formContact .invalid-feedback{
    text-align: start;
 }
@media only screen and (max-width: 768px) {
    .formContactContainer button, #contactResponse button{
      font-size: var(--text-button-font-size-mobile);
    }
    .formContactContainer label, #formContact input, #formContact textarea{
      font-size:var(--text-font-size-mobile);
    }
    .formContactContainer{
        width:100%;
        max-width: none;
    }
    #contactFormBox{
      margin-bottom: 7em;
      flex-direction: column;
      row-gap:3em;
      padding: 1em;
    }
    #contactFormContainer{
      margin-top: 0;
    }
 }