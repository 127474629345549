#experienceComponent{
    flex:1;
    display: flex;
    flex-direction: row;
    background-color: transparent;
}
#experienceContainer{
    background-color: var(--palette-dark);
}
#experienceBox{
    margin: auto;
    max-width: 80em;
    padding: 0 10%;
    width: -webkit-fill-available;
}
#experience_accordion{
    flex: 4;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}
#experienceBox .loader{
    width: 4em;
    margin: auto;
}
#experience_divider_1{
    animation: trip-forward-upside-down 8s ease-in-out infinite;
    animation-delay: 0.5ms;
}
#experience_divider_2{
    animation: trip-forward 6s ease-in-out infinite;
    animation-delay: 0.5ms;
}
#experienceBox .divTitle{
    color: var(--palette-light);
    text-align: start;
    margin-bottom: 5em;
}
#experienceBox .divTitle h2{
    color: var(--palette-light);
}
#experience_divider_1{
    transform: rotate(180deg) translateY(10px);
}
#experience_divider_1 .divider_wave,#experience_divider_2 .divider_wave{
    fill: var(--palette-light);
}
#experienceResults{
    display: flex;
    flex-direction: row;
    column-gap: 1em;
}
#filterBox{
    flex: 1;
    display: flex;
    flex-direction: column;
    width:100%;
    background-color: var(--accent);
    color:var(--palette-light);
    border-radius: 0;
    padding: 1em;
    max-width: 50rem;
    height: fit-content;
    background-size: 40px 40px;
}
#filterMinimized{
    display: flex;
    flex-direction: row;
}
#filterMinimized svg{
    flex:1;
    height: 3vh;
    transition: transform 0.3s ease-in-out;
    fill:var(--palette-light);
}
#filterMinimized span{
    flex:9;
    text-align: start;
    font-family: 'NeutralFace';
    font-size:smaller;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.flexCol{
    display: flex;
    flex-direction: column;
}
.flexWrap{
    flex-wrap: wrap;
}
.exp_project_name{
    font-family: 'NeutralFace';
    font-weight: 700;
    font-size: var(--body-font-size);
}
.exp_project_description{
    font-size: var(--body-font-smaller-size);
    font-family: 'Jura';
}
.exp_position{
    font-size: var(--body-font-smaller-size);
    text-transform: uppercase;
}
.exp_dates{
    font-size: var(--body-font-smaller-size);
    text-transform: uppercase;
}
.exp_position, .exp_dates{
    font-family: 'Jura';
}
.exp_tags span{
    font-family: 'NeutralFace-Bold';
}
.exp_position, .exp_dates, .exp_details, .exp_project_name, .exp_project_description{
    text-align: start;
}
.exp_details{
    padding: 1em;
    margin: 0;
    font-size:var(--body-font-smaller-size);
    font-family: 'Jura';
    font-weight: 900;
}
.experience_item.no_results{
    margin: 0;
    width: 100%;
}
.experience_item{
    display: flex;
    column-gap: 0.5em;
    max-width: 50rem;
    padding: 1em;
    background-color: var(--palette-light);
    position:relative;
    height: auto;
    width: 50%;
    transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}
.experience_item.expanded{
    width: 100%;
}
.exp_project_name, .exp_project_description,
.exp_position, .exp_dates, #experienceContainer hr {
    color: var(--palette-dark) !important;
}
.exp_expand_collapse{
    position:absolute;
    bottom:1em;
    right:1em;
    height:3vh;
    width:100%;
    text-align: right;
}
.exp_expand_collapse svg{
    height:inherit;
    transition: transform .3s ease-in-out;
    fill: var(--dark) !important;
}
.experience_item .flexCol {
    row-gap: 0.3em;
    flex:4;
}
.experience_item_body{
    flex:6;
    align-content: center;
}
.experience_item hr {
    width: 90%;
    margin-left: 0;
}
.exp_expand_collapse svg.expanded{
    transform: rotate(-180deg);
    transform-origin: 50% 50%;
}
#experience_top{
    display:flex;
}
#experienceContainer .accordion-button {
    color: transparent !important;
    background-color: transparent !important;
    padding: 0;
    box-shadow: none;
    position:relative;
}
#experienceContainer .accordion-body {
    text-align: start;
    padding:0;
    font-family: 'Sk-Modernist-Regular';
}
#experienceContainer .accordion-item{
    border:none;
    background-color:transparent;
}
#experienceContainer .accordion-item:has(button[aria-expanded='true']){
    grid-column: 1 / 4;
} 
#experienceContainer .accordion-button:after{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke-width='2' stroke='%23cd491b' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    background-size: auto;
    width: 1.5em;
    height: 1.5em;
}
.exp_tags{
    font-size:var(--body-font-tiny-size);
    justify-content: flex-start;
    column-gap: 1em;
    row-gap:1em;
}
.exp_tags span{
    padding: 0.1em 1em;
    border: 2px solid var(--accent);
    border-radius: var(--border-rounded-radius);
    color:var(--neutral);
    background-color:var(--accent);
}
.tagContainer{
    font-size:0.8em;
    border: 2px solid var(--palette-light);
    color:var(--palette-light);
    border-radius: var(--border-rounded-radius);
    padding: 0 1em;
    cursor:pointer;
}
#selectAll{
    font-family: 'Sk-Modernist-Regular';
    justify-content: start;
    column-gap: 2em;
    padding-bottom: 1em;
    font-size: smaller;
}
#selectTag{
    row-gap: .5em;
    column-gap: .5em;
    justify-content: start;
    font-family: 'NeutralFace-Bold';
    font-size: small;
}
.form-check-input:checked, .form-check-input::before {
    background-color: var(--palette-dark) !important;
    border-color: var(--accent-darker-more) !important;
}
#switch-select-all, #filterMinimized{
    cursor: pointer;
}
.tagContainer.selected{
    background-color:var(--palette-light);
    color:var(--palette-dark);
}
#filterMinimized svg.expanded{
    transform: rotate(-180deg);
    transform-origin: 50% 50%;
}
@media only screen and (max-width: 768px) {
    #experienceBox{
        margin: 0;
        padding: 1em;
    }
    #experienceResults{
        flex-direction: column;
        row-gap: 2em;;
    }
    .experience_item{
        width:60%;
        flex-direction: column;
    }
    .experience_item.expanded{
        height:unset;
    }
    .experience_item_body{
        flex:6;
    }
    .experience_item .flexCol{
        flex:4;
    }
}