@import url('https://fonts.googleapis.com/css2?family=Belleza&display=swap');
#welcomeComponent{
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-left:10%;
    background-image: radial-gradient(circle at 1px 1px, #000 1px, #0000 0);
    background-size: 40px 40px;
    height: 100vh;
    align-items: center;
}
#homeStart {
    cursor: pointer;  
    color: var(--palette-dark);
    display: flex;
    flex-direction: column;
    justify-items: end;
    align-items: end;
}
#homeStart svg{
    width: 25%;
}
#homeStart span:last-child{
    font-size: var(--page-h1-font-size);
    text-shadow: 4px 0 1px var(--palette-accent-warm);
    font-family: NeutralFace-Bold;
    text-shadow: 10px 0 1px #ff8923;
}
.mainName{
    row-gap: 0.5em;
    text-align: start;
    display: flex;
    flex-direction: column;
    padding: 0 0.5em;
}
#welcomeComponent .spacer{
    height: 2em;
}
#welcomeComponent .nameTitle{
    color: var(--palette-dark);
    font-family: NeutralFace-Bold;
    font-size: var(--page-h1-font-size);
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: normal;
    text-shadow: 3px 0 1px var(--palette-accent-warm), 10px 0 1px rgb(255 137 35);
}
#welcomeComponent .nameSubtitle{
    font-family: NeutralFace;
    display: flex;
    color:var(--palette-dark); 
    flex-direction: column;
}
#welcomeComponent .nameSubtitle h4 {
    font-size:var(--page-h4-font-size);
}
.homeLinks svg{
    width: 2em;
    height: 2em;
}
.homeLinks{
    display: flex;
    flex-direction: row;
    column-gap: 1em;
    width: 30%;
}
.homeLinks button{
    border: none;
    background: none;
    padding: 0;
}
.homeLinks a, .homeLinks button{
    flex:1;
    z-index:9999;
    cursor:pointer;
}
.homeLinks a:hover, .homeLinks button:hover{
    transform: scale(1.1);
    transform-origin: center;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.homeLinks.menuItem{
    background-color: transparent;
    border: none;
}
.menuItem:hover{
    transform: scale(1.1) !important;
    transition: cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
}
#welcomeComponent .mainName hr {
    width: 50%;
    margin: auto;
}
@media only screen and (max-width: 768px) {
    #welcomeComponent{
        padding: 1%;
    }
    .homeLinks{
        width:40%;
    }
    .homeLinks svg{
        height: 1.5em;
        width: 1.5em;
    }
}