.journal_item{
    background-color: var(--palette-lighter);
    border:1px solid var(--palette-dark);
    margin: auto;
    max-width: 50rem;
    position: relative;
    text-align: start;
    margin: 1em 0;
}
#journal_container {
    background: var(--palette-light);
    min-height:calc(100vh - var(--top-menu-height));
    justify-content: space-between;
    background-size: 40px 40px;
    background-image: linear-gradient(to right, #80808066 1px, transparent 1px), linear-gradient(to bottom, #80808066 1px, transparent 1px);
}
#journal_container .divTitle {
    text-align: start;
    margin-bottom: 5em;
}
#journal_container .divTitle h2 {
    color:var(--palette-dark);
    margin-bottom: 1em;
}
#journal_container .divTitle h3 {
    color:var(--palette-dark);
    margin-bottom: 1em;
}
#journal_divider_1{
    transform:rotate(180deg);
}
#journal_divider_1 svg, #journal_divider_2 svg{
    fill: var(--palette-dark);
}
.journal_main{
    padding: 1em;
    max-width: 50rem;
}
#journal_main{
    display: flex;
    flex-direction: column;
    row-gap: 3em;
}
#journal_box{
    max-width: 70em;
    padding: 0 10%;
    margin: 2em auto;
    width: -webkit-fill-available;
  }
.journal_entry_body{
    font-family: 'Sk-Modernist-Regular';
    margin: auto;
    padding: 2em 1em;
    position: relative;
    text-align: start;
    background: var(--palette-lighter);
    width: 100%;
}
.journal_body_preview{
    margin: 1em 0;
    font-family: 'Sk-Modernist-Regular';
    font-size:var(--text-button-font-size);
}
.journal_title{
    font-weight: 900;
    color: var(--palette-dark);
    font-size: var(--page-h2-font-size);
    font-family: 'NeutralFace';
}
.journal_date{
    font-weight: 500;
    font-size: 0.9em;
    width: fit-content;
    font-family: 'NeutralFace';
}
.journal_top{
    display: flex;
}
.journal_readMore{
    flex: 1;
    font-weight: 500;
    color: var(--palette-dark);
    font-family: 'NeutralFace-Bold';
    text-align: start;
    text-decoration: none;
    font-size: smaller;
}
.journal_nav{
    padding: .5em 1em;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .2rem;
    display: block;
    color: var(--neutral);
    font-family: 'NeutralFace-Bold';
    text-align: start;
    text-decoration: none;
    font-size: var(--text-button-font-size);
}
.journal_nav span{
    margin:auto;
}
.journal_back{
    justify-items: start;
}
.journal_back svg{
    width: 25%;
    justify-self: start;
    transform: rotate(180deg);
}
#entry_box {
    margin: 0 auto;
    max-width: 70em;
    min-width: 70vw;
    padding: 0 10% 5% 10%;
}
.divEntryTitle{
    text-align: start;
    width:auto;
    color:var(--palette-dark);
    margin: 1em 1em 0 1em;
    padding: 2em 0 0 0;
}
.divEntryTitle h2{
    font-size: 2em;
    letter-spacing: .2rem;
    font-family:'NeutralFace';
    font-weight: 400;
    font-style: normal;
}
.divEntryTitle h3{
    font-family:'NeutralFace';
    font-size: 1rem;
    font-weight: 400;
    background: var(--palette-accent-warm);
    padding: 0.5em;
    color: var(--palette-light);
}
@media only screen and (max-width: 768px) {
    #journal_box{
        margin: auto;
        padding: 10%;
      }
      .journal_title{
        font-size:var(--page-h2-font-size-mobile);
    }
      .journal_nav{
        font-size:var(--text-button-font-size-mobile);
    }
    .journal_body_preview{
        font-size:var(--text-button-font-size-mobile);
    }
 }