
#about_container{
  background-color: var(--palette-light);
  display: flex;
  flex-direction: column;
}
.about_paragraph p, .about_paragraph span{
  font-size: var(--body-font-size);
  line-height: 1.2em;
  margin:0;
  font-weight: 800;
  font-family: "Jura", sans-serif
}
.about_paragraph p strong{
  text-shadow: 1px 0 0 var(--neutral);
}
.about_paragraph .spacer{
  height: 5em;
}
#about_box_1{
  background-color: var(--palette-dark);
  display: flex;
  padding: var(--body-padding);
  min-height: 100vh;
  column-gap: 5em;
  align-items: center;
} 
#about_box_1 .divTitle{
  flex: 1;
  text-align: center;
}
#about_box_1 .divTitle h2{
  text-shadow: 0 0.5em 0 var(--palette-accent-warm), 0 1em 0 var(--palette-dark-transparent);
}
#about_box_1_body {
  flex: 2;
  border-left: 1px solid var(--neutral);
  padding-left: 3em;
}
.go_to_next_section{
    color: var(--neutral);
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 99999;
    transform: rotate(90deg);
    width: 5em;
    height: 5em;
    border-bottom-left-radius: 100%;
    background: var(--palette-dark);
}
.go_to_next_section svg{
  padding: 5% 20% 35% 40%;
  height: 100%;
}
#about_divider_1 .divider_wave{
  fill: var(--accent);
}
#about_container #about_divider_2{
  background: var(--palette-dark);
  transform: translateY(-2px);
}
#about_container #about_divider_2 svg{
  transform: translateY(4px);
}
#about_container #about_divider_2 .divider_wave{
  fill: var(--palette-accent-warm);
}
#about_divider_1{
  transform: rotate(180deg) scaleY(1.1);
}
#about_divider_3{
 height:15vh;
 background: var(--accent);
}
#about_divider_4{
  width: 100%;
  height: 23em;
  background: linear-gradient(0deg, #f0b46c, #cd491b);
}
#about_box_2{
  display: block;
  padding: var(--body-padding);
  text-align: end;
  min-height: 100vh;
  align-content: center;
}
#about_box_2_title{
  width: 70%;
  margin: auto;
  text-align: center;
  border: 2px solid var(--palette-dark);
  padding: 0.5em;
}
#about_box_2 h2{
  color: var(--palette-dark);
  font-family: 'NeutralFace-Bold';
  text-transform: uppercase;
  font-size: var(--page-h2-font-size);
  margin: 0;
}
#about_box_2 svg{
  width: 60%;
  margin: auto;
}
#about_box_2 svg line{
  stroke-dasharray: 3;
}
#about_box_3{
  display: flex;
  flex-direction: column;
  padding: var(--body-padding);
  background-color: var(--palette-accent-warm);
  min-height: 100vh;
  align-content: space-around;
}
#about_box_3 span{
  color:var(--neutral);
  font-family: 'NeutralFace';
  font-size: x-large;
}
#about_box_3 span strong{
  font-family: 'NeutralFace-Bold';
}
#about_box_2 h3{
  color: var(--palette-dark);
  border-bottom: 2px solid var(--palette-dark);
  font-family: 'NeutralFace-Bold';
  text-transform: uppercase;
  font-weight: 900;
  font-size:var(--page-h3-font-size);
}
#about_box_2 span{
  color:var(--palette-dark);
  font-size:var(----body-font-size);
}
#about_box_4{
  padding: 10em 20em;
  background-color: var(--palette-accent-warm);
  color: var(--palette-dark);
}
#about_box_1 .about_paragraph{
  color: var(--neutral);
  display: flex;
  flex-direction: row;
}
#about_box_2 .about_paragraph{
  display: flex;
  flex-direction: row;
  row-gap: 5em;
  column-gap: 5em;
}
#about_box_2 .about_paragraph>div{
  flex: 1;
  text-align: start;
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  padding: 2em;
  border: 2px solid var(--palette-dark);
  box-shadow: .5em .5em var(--palette-dark);
}
.about_paragraph{ 
  flex: 2;
  text-align: start;
  align-content: center;
  color: var(--palette-dark);
}
#about_grid{
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20viewBox='0%200%201200%2095'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient%20id='gradient'%3E%3Cstop%20offset='15%'%20stop-color='%23e97d3e'%20/%3E%3Cstop%20offset='30%'%20stop-color='%23f0b46c'%20/%3E%3Cstop%20offset='70%'%20stop-color='%23f0b46c'%20/%3E%3Cstop%20offset='85%'%20stop-color='%23e97d3e'%20/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect%20width='100%'%20height='95'%20fill='none'/%3E%3Cpath%20d='M0%2069L50%2065C100%2061%20200%2052%20300%2035C400%2018%20500%20-7%20600%201C700%2010%20800%2052%20900%2069C1000%2086%201100%2078%201150%2074L1200%2069V95H1150C1100%2095%201000%2095%20900%2095C800%2095%20700%2095%20600%2095C500%2095%20400%2095%20300%2095C200%2095%20100%2095%2050%2095H0V69Z'%20fill='%23ece6e178'%20/%3E%3C/svg%3E");
  background-size: auto;
  background-repeat: repeat no-repeat;
  height: 5em;
  transform: rotate(180deg);
}
.about_box_3{
}
#get_to_know_me{
  font-size: xxx-large;
  font-family: 'NeutralFace-Bold';
  fill: var(--palette-accent-warm);
}
#about_cards{
  display: flex;
  flex-direction: column;
  padding: 6em 2em 2em 3em;
  background: var(--palette-light);
  background-image: radial-gradient(circle at 1px 1px, #000 1px, #0000 0);
  background-size: 40px 40px;
}
.about_cards_row{
  display: flex;
  column-gap: 1em;
}
.about_cards_item{
  height:auto;
  width: 100%;
  background-color: var(--palette-light);
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about_cards_item.dark{
  background-color: var(--palette-dark);
}
.about_cards_item_label{
  color: var(--palette-dark);
  font-size: small;
  font-weight: 800;
  font-family: Jura, sans-serif !important;
  text-transform: uppercase;
}
.about_cards_item_val{
  color: var(--palette-dark);
  font-size: large;
  font-family: "NeutralFace-Bold";
}
.about_cards_item.dark .about_cards_item_label, .about_cards_item.dark .about_cards_item_val{
  color: var(--palette-light);
}
.about_header{
  background: repeating-radial-gradient(black, transparent 100px);
  justify-items: center;
}
.about_header img{
  width: 10em;
  border-radius: 50%;
  margin: auto;
  border: .3em solid var(--palette-dark);
  background: repeating-radial-gradient(#000, var(--palette-light) 100px);
  transform: translateY(5em);
}
#about_box_3_container{
  background: var(--palette-light);
  margin: 10% 0;
  max-width: 36em;
  align-self: center;
  box-shadow: 25px 22px 0 var(--accent-darkest), -45px -20px 0 var(--accent-darker-more), 60px -50px 0 var(--accent-darker);
}
svg.view-desktop{
  display: block;
}
svg.view-mobile{
  display: none;
}
@media only screen and (max-width: 768px) {
  #about_box_1 .about_paragraph{
    flex-direction: column;
  }
  #about_box_1{
    padding: 2em 1em 8em 1em;
    row-gap: 1em;
    flex-direction: column-reverse;
  }
  #about_box_1 .divTitle{
    text-align: start;
    padding: 2em 0;
  }
  #about_box_1_body{
    border: none;
    padding: 0;
  }
  .about_cards_row{
    flex-direction: column;
  }
  #about_box_2{
    padding:10em 1em;
  }
  #about_box_3{
    padding: 10em 1em;
  }
  #about_box_4{
    padding: 5em 1em;
  }
  .about_fact_item span{
    font-size:1em;
    margin: 0;
  }
  #about_box_2 .about_paragraph{
    flex-direction: column;
    row-gap: normal;
  }
  #get_to_know_me{
    font-size:6rem;
  }
  #about_box_2 h2{
    font-size: x-large;
  }
  svg.view-desktop{
    display: none;
  }
  svg.view-mobile{
    display: block;
  }
}